import { useNavigate } from 'react-router-dom'
import PageDropdown from '../PageDropdown/PageDropdown'
import useLead from '../../hooks/useLead'
import css from './LeadHeader.module.css'

const LeadHeader = ({ label }) => {

  const navigate = useNavigate()

  const { id, lead } = useLead()

  const handleNavigate = (path) => ()=> {
    navigate(`/leads/${id}/${path}`)
  }

  const pages = [
    { name: 'Ficha', icon: 'person', onClick: handleNavigate('ficha') },
    { name: 'Contactos', icon: 'fact_check', onClick: handleNavigate('contactos') },
    { name: 'Classfy', icon: 'all_inclusive', onClick: handleNavigate('classfy') },
  ]

  const leadName = lead.nombreAlumno || lead.nombreLead || 'lead'

  return (
    <header className={css.header}>
      <div className={css.headerIcon}>
        <i className='material-icons'>{pages.find(page=> page.name === label).icon}</i>
      </div>
      <PageDropdown
        label={`${label} de ${leadName}`}
        pages={pages}
      />
    </header>
  )
}

export default LeadHeader